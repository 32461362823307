/*
 * Mirabilis styles
 */

@import 'react-day-picker/lib/style.css';
@import '@adyen/adyen-web/dist/adyen.css';

@import 'bva-css-toolkit';

@import './modules/colours.css';
@import './modules/datepicker.css';
@import './modules/grid.css';
@import './modules/headers.css';
@import './modules/icons.css';
@import './modules/layout.css';
@import './modules/spaces.css';
@import './modules/transitions.css';
@import './modules/type.css';
@import './modules/video.css';
@import './modules/payment.css';
@import './modules/forms.css';
@import './modules/hover.css';
@import './modules/loader.css';
@import './modules/flex.css';

@custom-media --breakpoint-sm (min-width: 760px);   /* Tablet */
@custom-media --breakpoint-md (min-width: 1030px);  /* Small desktop */
@custom-media --breakpoint-lg (min-width: 1600px);  /* Large desktop */

:root {
    --container-width: 1200px;
}

.container {
    max-width: var(--container-width);
}

*:focus {
    outline: none;
}

.pixelated {
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
}

.sticky {
    position: sticky;
}


.map {
    height: 50vh;
    width: 100%;
    min-height: 400px;
    max-height: 800px;
}

.overflow-y-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.modal-open .book-button-wrap {
    display: none;
}

/*
 * form
 */

.form-field-reset {
    -webkit-appearance: none;
    background-color: transparent;
    background-image: none;
    border: 0;
    border-radius: 0;
    height: auto;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.form-field-reset:hover { text-decoration: none; }

.form-field-reset:focus {
    box-shadow: none;
    outline: none;
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}
