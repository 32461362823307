/*
 * Video
 */

.video-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    transition: opacity 1.5s cubic-bezier(.45,.65,.34,.96);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
