.flex {
    display: flex;
}
.flex-wrap {
    flex-wrap: wrap;
}
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
}
.items-bottom {
    align-items: flex-end;
}
@media (--breakpoint-sm) {
    .sm-flex {
        display: flex;
    }
}
