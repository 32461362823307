/*
 * Datepicker
 */

.DayPicker {
    display: block;
}

.DayPicker-Month {
    display: table;
    float: left;
    width: calc(100% - (2 * var(--space-3)));
    margin: var(--space-3);
    background: var(--white);
}

@media (--breakpoint-sm) {
    .tabletDouble .DayPicker-Month {
        width: calc(50% - (2 * var(--space-3)));
    }
}

@media (--breakpoint-md) {
    .DayPicker-Month {
        width: calc(50% - (2 * var(--space-3)));
    }
}

.DayPicker-Day {
    position: relative;
    padding: 0;
    width: calc(100% / 7);
    height: 0;
    padding-bottom: calc(100% / 7);
    border: 1px solid var(--grey-3);
    margin-right: -1px;
    margin-bottom: -1px;
    border-radius: 0;
}

.DayPicker-Day.selected {
    background-color: var(--green-2);
}

.DayPicker-Day.start.selected, .DayPicker-Day.end.selected {
    background-color: var(--grey);
    color: var(--white);
}

.DayPicker-Day.outside {
    background: transparent !important;
    border: 1px transparent !important;
}

.DayPicker-Day:hover:not(.outside) {
    background-color: var(--grey-5) !important;
}

.DayPicker-Day.selected:hover {
    background-color: var(--grey) !important;
    color: var(--white);
}

.DayPicker-Day.disabled {
    pointer-events: none;
    color: var(--grey-3);
}

.DayPicker-Caption > div {
    font-weight: inherit;
    font-size: inherit;
}

.DayPicker-NavButton--prev, .DayPicker-NavButton--next {
    background-size: 100%;
    height: var(--line-height-h3);
}

.DayPicker-NavButton--prev {
    left: var(--space-3);
    background-image: url("/images/icon-arrow.svg");
    transform-origin: center;
    transform: rotate(180deg);
}

.DayPicker-NavButton--next {
    right: var(--space-3);
    background-image: url("/images/icon-arrow.svg");
    background-size: 100%;
}

@media (--breakpoint-sm) {
    .DayPicker-NavButton--prev, .DayPicker-NavButton--next {
        height: var(--sm-line-height-h3);
    }
}

@media (--breakpoint-md) {
    .DayPicker-NavButton--prev, .DayPicker-NavButton--next {
        height: var(--md-line-height-h3);
    }
}
