/*
 * Type
 */

@font-face {
    font-display: swap;
    font-family: 'Halis';
    font-weight: normal;
    src: url('/fonts/38932E_6_0.eot');
    src: url('/fonts/38932E_6_0.eot?#iefix') format('embedded-opentype'),
         url('/fonts/38932E_6_0.woff2') format('woff2'),
         url('/fonts/38932E_6_0.woff') format('woff'),
         url('/fonts/38932E_6_0.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Halis';
    font-weight: bold;
    src: url('/fonts/38932E_5_0.eot');
    src: url('/fonts/38932E_5_0.eot?#iefix') format('embedded-opentype'),
         url('/fonts/38932E_5_0.woff2') format('woff2'),
         url('/fonts/38932E_5_0.woff') format('woff'),
         url('/fonts/38932E_5_0.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'HalisR-Light';
    src: url('/fonts/38932E_3_0.eot');
    src: url('/fonts/38932E_3_0.eot?#iefix') format('embedded-opentype'),
         url('/fonts/38932E_3_0.woff2') format('woff2'),
         url('/fonts/38932E_3_0.woff') format('woff'),
         url('/fonts/38932E_3_0.ttf') format('truetype');
}


:root {

    --font-family: 'Halis', sans-serif;
    --sans-family: 'Halis', sans-serif;

    --body-font-size: 1rem;      /* 13px */
    --h1: 1.6923076923rem;       /* 22px */
    --h2: 1.2307692308rem;       /* 16px */
    --h3: 0.8461538462rem;       /* 11px */
    --h4: var(--body-font-size); /* 13px */
    --h5: var(--body-font-size); /* 13px */

    --sm-body-font-size: 1.0769230769rem; /* 14px */
    --sm-h1: 1.8461538462rem;             /* 24px */
    --sm-h2: 1.3846153846rem;             /* 18px */
    --sm-h3: 0.9230769231rem;             /* 12px */
    --sm-h4: var(--sm-body-font-size);    /* 14px */
    --sm-h5: 1.2307692308rem;             /* 16px */

    --body-line-height: 2rem;                  /* 26px */
    --line-height-h1: 2.5rem;                  /* 32.5px */
    --line-height-h2: var(--body-line-height); /* 26px */
    --line-height-h3: var(--body-line-height); /* 26px */
    --line-height-h4: var(--body-line-height); /* 26px */
    --line-height-h5: var(--body-line-height); /* 26px */
    --line-height-5: 5rem;                     /* 65px */

    --sm-body-line-height: 2rem;                     /* 26px */
    --sm-line-height-h1: 3rem;                       /* 39px */
    --sm-line-height-h2: 2.3846153846rem;            /* 31px */
    --sm-line-height-h3: var(--sm-body-line-height); /* 26px */
    --sm-line-height-h5: var(--sm-body-line-height); /* 26px */
    --sm-line-height-h5: var(--sm-body-line-height); /* 26px */

    --md-body-line-height: var(--sm-body-line-height);
    --md-line-height-h1: var(--sm-line-height-h1);
    --md-line-height-h2: var(--sm-line-height-h2);
    --md-line-height-h3: var(--sm-line-height-h3);
    --md-line-height-h4: var(--sm-line-height-h4);
    --md-line-height-h5: var(--sm-line-height-h5);

    --lg-body-line-height: var(--md-body-line-height);
    --lg-line-height-h1: var(--md-line-height-h1);
    --lg-line-height-h2: var(--md-line-height-h2);
    --lg-line-height-h3: var(--md-line-height-h3);
    --lg-line-height-h4: var(--md-line-height-h4);
    --lg-line-height-h5: var(--md-line-height-h5);

    --letter-spacing-h1: 0.3076923077rem;            /* 4px */
    --letter-spacing-h2: 0.2307692308rem;            /* 3px */
    --letter-spacing-h3: 0.09230769231rem;           /* 1.2px */
    --letter-spacing-h4: 0.08307692308rem;           /* 1.08px */
    --letter-spacing-h5:  var(--letter-spacing-h4);  /* 1.08px */
    --body-letter-spacing: var(--letter-spacing-h4); /* 1.08px */

    --sm-letter-spacing-h1: 0.3615384615rem;               /* 4.7px */
    --sm-letter-spacing-h2: 0.2846153846rem;               /* 3.7px */
    --sm-letter-spacing-h3: 0.15rem;                       /* 1.95px */
    --sm-letter-spacing-h4: 0.1rem;                        /* 1.3px */
    --sm-letter-spacing-h5: var(--sm-letter-spacing-h4);   /* 1.3px */
    --sm-body-letter-spacing: var(--sm-letter-spacing-h4); /* 1.3px */

    --md-letter-spacing-h1: var(--sm-letter-spacing-h1);
    --md-letter-spacing-h2: var(--sm-letter-spacing-h2);
    --md-letter-spacing-h3: var(--sm-letter-spacing-h3);
    --md-letter-spacing-h4: var(--sm-letter-spacing-h4);
    --md-letter-spacing-h5: var(--sm-letter-spacing-h5);

    --lg-letter-spacing-h1: var(--md-letter-spacing-h1);
    --lg-letter-spacing-h2: var(--md-letter-spacing-h2);
    --lg-letter-spacing-h3: var(--md-letter-spacing-h3);
    --lg-letter-spacing-h4: var(--md-letter-spacing-h4);
    --lg-letter-spacing-h5: var(--md-letter-spacing-h5);

    --text-transform-h1: uppercase;
    --text-transform-h2: uppercase;
    --text-transform-h3: uppercase;
    --text-transform-h4: none;
    --text-transform-h5: none;

    --sm-text-transform-h1: var(--text-transform-h1);
    --sm-text-transform-h2: var(--text-transform-h2);
    --sm-text-transform-h3: var(--text-transform-h3);
    --sm-text-transform-h4: var(--text-transform-h4);
    --sm-text-transform-h5: var(--text-transform-h5);

    --md-text-transform-h1: var(--sm-text-transform-h1);
    --md-text-transform-h2: var(--sm-text-transform-h2);
    --md-text-transform-h3: var(--sm-text-transform-h3);
    --md-text-transform-h4: var(--sm-text-transform-h4);
    --md-text-transform-h5: var(--sm-text-transform-h5);

    --lg-text-transform-h1: var(--md-text-transform-h1);
    --lg-text-transform-h2: var(--md-text-transform-h2);
    --lg-text-transform-h3: var(--md-text-transform-h3);
    --lg-text-transform-h4: var(--md-text-transform-h4);
    --lg-text-transform-h5: var(--md-text-transform-h5);

}

/* Global */

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

html {
    font-size: 13px;
}

body {
    letter-spacing: var(--body-letter-spacing);
    line-height: var(--body-line-height);
}
.light {
    font-family: 'HalisR-Light';
}



@media (--breakpoint-sm) {
    body {
        letter-spacing: var(--sm-body-letter-spacing);
        line-height: var(--sm-body-line-height);
    }
}

/* Layout */

.text-columns-2 {
    column-count: 2;
    column-gap: var(--space-6);
}

@media (--breakpoint-sm) {
    .sm-centre {
        text-align: center;
    }
    .sm-text-columns-2 {
        column-count: 2;
        column-gap: var(--space-6);
    }
}

/* Links */

a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid var(--grey-3);
}

.underline {
    text-decoration: none;
    border-bottom: 1px solid var(--grey-3);
}

.link-reset {
    border-bottom: none;
    -webkit-appearance: unset;
}
.center {
    text-align: center;
}

/* Rich Text */
.rich-text h2 {
    margin-top: var(--space-8);
    margin-bottom: var(--space-5);
}
.rich-text p {
    margin-top: var(--space-4);
}

.rich-text p:first-child {
    margin-top: 0;
}

.rich-text p:last-child {
    margin-bottom: 0;
}
.rich-text > h2:first-child {
    margin-top: 0;
}
.rich-text ul {
    list-style: none;
    padding-left: 2em;
    position: relative;
}
.rich-text li:before {
    content: "–";
    position: absolute;
    left: 0;
}

.max-width-520 {
    max-width: 520px;
}
.max-width-720 {
    max-width: 720px;
}

/* Fields */

select.centre {
    text-align-last: center;
}

@media (--breakpoint-sm) {
    select.sm-centre {
        text-align-last: center;
    }
}

/* Main configuration */

h1, .h1 {
    line-height: var(--line-height-h1);
    letter-spacing: var(--letter-spacing-h1);
    text-transform: var(--text-transform-h1);
}
h2, .h2 {
    line-height: var(--line-height-h2);
    letter-spacing: var(--letter-spacing-h2);
    text-transform: var(--text-transform-h2);
}
h3, .h3 {
    line-height: var(--line-height-h3);
    letter-spacing: var(--letter-spacing-h3);
    text-transform: var(--text-transform-h3);
}
h4, .h4 {
    line-height: var(--line-height-h4);
    letter-spacing: var(--letter-spacing-h4);
    text-transform: var(--text-transform-h4);
}
h5, .h5 {
    line-height: var(--line-height-h5);
    letter-spacing: var(--letter-spacing-h5);
    text-transform: var(--text-transform-h5);
}

.line-height-5 {
    line-height: var(--line-height-5);
}

/* Footer */
footer hr {
    border-style: solid;
    border-color: var(--grey-3);
    border-bottom:0;
    margin-bottom:0;
    margin-top: var(--space-5);
}
footer h4 {
    text-transform: uppercase;
    color: var(--grey-3);
    margin-top: var(--space-5);
    margin-bottom: var(--space-5);
}
header a,
footer a {
    text-transform: uppercase;
    border-bottom:0;
}
footer .col p:first-child {
    margin:0;
}

footer .rich-text p {
    margin-top: var(--space-3);
}
blockquote:before {
    margin-left:-10px;
    content: "“";
}
blockquote:after {
    content: "”";
}
blockquote {
    margin: 0;
    margin-top: var(--space-4);
    font-size: var(--h1);
}

@media (--breakpoint-sm) {
    h1, .sm-h1 {
        line-height: var(--sm-line-height-h1);
        letter-spacing: var(--sm-letter-spacing-h1);
        text-transform: var(--sm-text-transform-h1);
    }
    h2, .sm-h2 {
        line-height: var(--sm-line-height-h2);
        letter-spacing: var(--sm-letter-spacing-h2);
        text-transform: var(--sm-text-transform-h2);
    }
    h3, .sm-h3 {
        line-height: var(--sm-line-height-h3);
        letter-spacing: var(--sm-letter-spacing-h3);
        text-transform: var(--sm-text-transform-h3);
    }
    h4, .sm-h4 {
        line-height: var(--sm-line-height-h4);
        letter-spacing: var(--sm-letter-spacing-h4);
        text-transform: var(--sm-text-transform-h4);
    }
    h5, .sm-h5 {
        line-height: var(--sm-line-height-h5);
        letter-spacing: var(--sm-letter-spacing-h5);
        text-transform: var(--sm-text-transform-h5);
    }

}
@media (--breakpoint-md) {
    .sm-h1 {
        line-height: var(--md-line-height-h1);
        letter-spacing: var(--md-letter-spacing-h1);
        text-transform: var(--md-text-transform-h1);
    }
    .sm-h2 {
        line-height: var(--md-line-height-h2);
        letter-spacing: var(--md-letter-spacing-h2);
        text-transform: var(--md-text-transform-h2);
    }
    .sm-h3 {
        line-height: var(--md-line-height-h3);
        letter-spacing: var(--md-letter-spacing-h3);
        text-transform: var(--md-text-transform-h3);
    }
    .sm-h4 {
        line-height: var(--md-line-height-h4);
        letter-spacing: var(--md-letter-spacing-h4);
        text-transform: var(--md-text-transform-h4);
    }
    .sm-h5 {
        line-height: var(--md-line-height-h5);
        letter-spacing: var(--md-letter-spacing-h5);
        text-transform: var(--md-text-transform-h5);
    }
    footer hr {
        margin-top: var(--space-8);
    }
}
@media (--breakpoint-md) {
    h1, .md-h1 {
        line-height: var(--md-line-height-h1);
        letter-spacing: var(--md-letter-spacing-h1);
        text-transform: var(--md-text-transform-h1);
    }
    h2, .md-h2 {
        line-height: var(--md-line-height-h2);
        letter-spacing: var(--md-letter-spacing-h2);
        text-transform: var(--md-text-transform-h2);
    }
    h3, .md-h3 {
        line-height: var(--md-line-height-h3);
        letter-spacing: var(--md-letter-spacing-h3);
        text-transform: var(--md-text-transform-h3);
    }
    h4, .md-h4 {
        line-height: var(--md-line-height-h4);
        letter-spacing: var(--md-letter-spacing-h4);
        text-transform: var(--md-text-transform-h4);
    }
    h5, .md-h5 {
        line-height: var(--md-line-height-h5);
        letter-spacing: var(--md-letter-spacing-h5);
        text-transform: var(--md-text-transform-h5);
    }
}
@media (--breakpoint-lg) {
    .sm-h1 {
        line-height: var(--lg-line-height-h1);
        letter-spacing: var(--lg-letter-spacing-h1);
        text-transform: var(--lg-text-transform-h1);
    }
    .sm-h2 {
        line-height: var(--lg-line-height-h2);
        letter-spacing: var(--lg-letter-spacing-h2);
        text-transform: var(--lg-text-transform-h2);
    }
    .sm-h3 {
        line-height: var(--lg-line-height-h3);
        letter-spacing: var(--lg-letter-spacing-h3);
        text-transform: var(--lg-text-transform-h3);
    }
    .sm-h4 {
        line-height: var(--lg-line-height-h4);
        letter-spacing: var(--lg-letter-spacing-h4);
        text-transform: var(--lg-text-transform-h4);
    }
    .sm-h5 {
        line-height: var(--lg-line-height-h5);
        letter-spacing: var(--lg-letter-spacing-h5);
        text-transform: var(--lg-text-transform-h5);
    }
}
@media (--breakpoint-lg) {
    .md-h1 {
        line-height: var(--lg-line-height-h1);
        letter-spacing: var(--lg-letter-spacing-h1);
        text-transform: var(--lg-text-transform-h1);
    }
    .md-h2 {
        line-height: var(--lg-line-height-h2);
        letter-spacing: var(--lg-letter-spacing-h2);
        text-transform: var(--lg-text-transform-h2);
    }
    .md-h3 {
        line-height: var(--lg-line-height-h3);
        letter-spacing: var(--lg-letter-spacing-h3);
        text-transform: var(--lg-text-transform-h3);
    }
    .md-h4 {
        line-height: var(--lg-line-height-h4);
        letter-spacing: var(--lg-letter-spacing-h4);
        text-transform: var(--lg-text-transform-h4);
    }
    .md-h5 {
        line-height: var(--lg-line-height-h5);
        letter-spacing: var(--lg-letter-spacing-h5);
        text-transform: var(--lg-text-transform-h5);
    }
}
@media (--breakpoint-lg) {
    h1, .lg-h1 {
        line-height: var(--lg-line-height-h1);
        letter-spacing: var(--lg-letter-spacing-h1);
        text-transform: var(--lg-text-transform-h1);
    }
    h2, .lg-h2 {
        line-height: var(--lg-line-height-h2);
        letter-spacing: var(--lg-letter-spacing-h2);
        text-transform: var(--lg-text-transform-h2);
    }
    h3, .lg-h3 {
        line-height: var(--lg-line-height-h3);
        letter-spacing: var(--lg-letter-spacing-h3);
        text-transform: var(--lg-text-transform-h3);
    }
    h4, .lg-h4 {
        line-height: var(--lg-line-height-h4);
        letter-spacing: var(--lg-letter-spacing-h4);
        text-transform: var(--lg-text-transform-h4);
    }
    h5, .lg-h5 {
        line-height: var(--lg-line-height-h5);
        letter-spacing: var(--lg-letter-spacing-h5);
        text-transform: var(--lg-text-transform-h5);
    }
}

.text-transform-none {
    text-transform: none;
}
