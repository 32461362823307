.hover-bg-black:hover {
    background: var(--black);
    color: var(--white);
    transition: all 0.3s;
    border-color: var(--white);
}
.hover-bg-white {
    border: 1px solid transparent;
}
.hover-bg-white:hover {
    background: var(--white);
    color: var(--black);
    transition: all 0.3s;
    border: 1px solid var(--black);
}
.hover-bg-green:hover {
    background: var(--green);
    color: var(--black);
    z-index: 1;
}
.hover-green:hover {
    color: var(--green);
}

header a:hover,
footer a:hover {
    color: var(--green);
}
