/*
 * Colours
 */

:root {

    --black: #000;

    --grey: #1c1c1c;
    --grey-2: #494949;
    --grey-3: #999999;
    --grey-4: #e1e1e1;
    --grey-5: #f1f1f1;

    --white: #fff;

    --green: #bac78f;
    --green-2: #dce3c7;
    --red: #EA7F7F;
    --pink: #FEE5DE;

    --muted: 0.3;
    --muted-2: 0.7;
    --muted-3: 0.1;

}

.grey-2 { color: var(--grey-2); }
.grey-3 { color: var(--grey-3); }
.grey-4 { color: var(--grey-4); }
.grey-5 { color: var(--grey-5); }

.green-2 { color: var(--green-2); }

.bg-grey-2 { background-color: var(--grey-2); }
.bg-grey-3 { background-color: var(--grey-3); }
.bg-grey-4 { background-color: var(--grey-4); }
.bg-grey-5 { background-color: var(--grey-5); }

.bg-green-2 { background-color: var(--green-2); }

.bg-pink { background-color: var(--pink); }

.border-grey-2 { border-color: var(--grey-2); }
.border-grey-3 { border-color: var(--grey-3); }
.border-grey-4 { border-color: var(--grey-4); }
.border-grey-5 { border-color: var(--grey-5); }

.border-green-2 { border-color: var(--green-2); }

.muted-2 {
    opacity: var(--muted-2);
}
.muted-3 {
    opacity: var(--muted-3);
}

@media (--breakpoint-sm) {

    .sm-white {
        color: var(--white);
    }

    .sm-bg-transparent {
        background-color: transparent;
    }

    .sm-border-grey-2 { border-color: var(--grey-2); }
    .sm-border-grey-3 { border-color: var(--grey-3); }
    .sm-border-grey-4 { border-color: var(--grey-4); }
    .sm-border-grey-5 { border-color: var(--grey-5); }

}

.border-transparent {
    border-color: transparent;
}
