/*
 * Transitions
 */

.transition-transform {
    transition: transform 0.25s linear 0s;
}

/* ReactCSSTransition Fade */

.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 0.2s ease-in;
}

/* ReactCSSTransition FadeToggle */

.fadeToggle-enter {
    opacity: 0.01;
}

.fadeToggle-enter-active {
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(.75, 0, 1, 0);
}

.fadeToggle-exit {
    opacity: 1;
}

.fadeToggle-exit-active {
    opacity: 0.01;
    transition: opacity 0.1s cubic-bezier(0, 1, .25, 1);
}
