/*
 * Grid
 */

.grid {
    display: -ms-grid;
    display: grid;
}

.grid-1 {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
}
.grid-1 > *.grid-cell:nth-of-type(1) {
    -ms-grid-column: 1;
    grid-column: 1;
}

.grid-2 {
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-columns: (1fr)[2];
}
.grid-2 > *.grid-cell:nth-of-type(2n + 1) {
    -ms-grid-column: 1;
    grid-column: 1;
}
.grid-2 > *.grid-cell:nth-of-type(2n) {
    -ms-grid-column: 2;
}

.grid-3 {
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-columns: (1fr)[3];
}
.grid-3 > *.grid-cell:nth-of-type(3n + 1) {
    grid-column: 1;
    -ms-grid-column: 1;
}
.grid-3 > *.grid-cell:nth-of-type(3n + 2) {
    grid-column: 2;
    -ms-grid-column: 2;
}
.grid-3 > *.grid-cell:nth-of-type(3n) {
    grid-column: 3;
    -ms-grid-column: 3;
}

.grid-4 {
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-columns: (1fr)[4];
}
.grid-4 > *.grid-cell:nth-of-type(4n + 1) {
    grid-column: 1;
    -ms-grid-column: 1;
}
.grid-4 > *.grid-cell:nth-of-type(4n + 2) {
    grid-column: 2;
    -ms-grid-column: 2;
}
.grid-4 > *.grid-cell:nth-of-type(4n + 3) {
    grid-column: 3;
    -ms-grid-column: 3;
}
.grid-4 > *.grid-cell:nth-of-type(4n) {
    grid-column: 4;
    -ms-grid-column: 4;
}

@media (--breakpoint-sm) {

    .sm-grid {
        display: -ms-grid;
        display: grid;
    }

    .sm-grid-1 {
        grid-template-columns: 1fr;
        -ms-grid-columns: 1fr;
    }
    .sm-grid-1 > *.grid-cell:nth-of-type(1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }

    .sm-grid-2 {
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-columns: (1fr)[2];
    }
    .sm-grid-2 > *.grid-cell:nth-of-type(2n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .sm-grid-2 > *.grid-cell:nth-of-type(2n) {
        grid-column: 2;
        -ms-grid-column: 2;
    }

    .sm-grid-3 {
        grid-template-columns: repeat(3, 1fr);
        -ms-grid-columns: (1fr)[3];
    }
    .sm-grid-3 > *.grid-cell:nth-of-type(3n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .sm-grid-3 > *.grid-cell:nth-of-type(3n + 2) {
        grid-column: 2;
        -ms-grid-column: 2;
    }
    .sm-grid-3 > *.grid-cell:nth-of-type(3n) {
        grid-column: 3;
        -ms-grid-column: 3;
    }

    .sm-grid-4 {
        grid-template-columns: repeat(4, 1fr);
        -ms-grid-columns: (1fr)[4];
    }
    .sm-grid-4 > *.grid-cell:nth-of-type(4n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .sm-grid-4 > *.grid-cell:nth-of-type(4n + 2) {
        grid-column: 2;
        -ms-grid-column: 2;
    }
    .sm-grid-4 > *.grid-cell:nth-of-type(4n + 3) {
        grid-column: 3;
        -ms-grid-column: 3;
    }
    .sm-grid-4 > *.grid-cell:nth-of-type(4n) {
        grid-column: 4;
        -ms-grid-column: 4;
    }

}

@media (--breakpoint-md) {

    .md-grid {
        display: -ms-grid;
        display: grid;
    }

    .md-grid-1 {
        grid-template-columns: 1fr;
        -ms-grid-columns: 1fr;
    }
    .md-grid-1 > *.grid-cell:nth-of-type(1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }

    .md-grid-2 {
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-columns: (1fr)[2];
    }
    .md-grid-2 > *.grid-cell:nth-of-type(2n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .md-grid-2 > *.grid-cell:nth-of-type(2n) {
        grid-column: 2;
        -ms-grid-column: 2;
    }

    .md-grid-3 {
        grid-template-columns: repeat(3, 1fr);
        -ms-grid-columns: (1fr)[3];
    }
    .md-grid-3 > *.grid-cell:nth-of-type(3n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .md-grid-3 > *.grid-cell:nth-of-type(3n + 2) {
        grid-column: 2;
        -ms-grid-column: 2;
    }
    .md-grid-3 > *.grid-cell:nth-of-type(3n) {
        grid-column: 3;
        -ms-grid-column: 3;
    }

    .md-grid-4 {
        grid-template-columns: repeat(4, 1fr);
        -ms-grid-columns: (1fr)[4];
    }
    .md-grid-4 > *.grid-cell:nth-of-type(4n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .md-grid-4 > *.grid-cell:nth-of-type(4n + 2) {
        grid-column: 2;
        -ms-grid-column: 2;
    }
    .md-grid-4 > *.grid-cell:nth-of-type(4n + 3) {
        grid-column: 3;
        -ms-grid-column: 3;
    }
    .md-grid-4 > *.grid-cell:nth-of-type(4n) {
        grid-column: 4;
        -ms-grid-column: 4;
    }

}

@media (--breakpoint-lg) {

    .lg-grid {
        display: -ms-grid;
        display: grid;
    }

    .lg-grid-1 {
        grid-template-columns: 1fr;
        -ms-grid-columns: 1fr;
    }
    .lg-grid-1 > *.grid-cell:nth-of-type(1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }

    .lg-grid-2 {
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-columns: (1fr)[2];
    }
    .lg-grid-2 > *.grid-cell:nth-of-type(2n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .lg-grid-2 > *.grid-cell:nth-of-type(2n) {
        grid-column: 2;
        -ms-grid-column: 2;
    }

    .lg-grid-3 {
        grid-template-columns: repeat(3, 1fr);
        -ms-grid-columns: (1fr)[3];
    }
    .lg-grid-3 > *.grid-cell:nth-of-type(3n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .lg-grid-3 > *.grid-cell:nth-of-type(3n + 2) {
        grid-column: 2;
        -ms-grid-column: 2;
    }
    .lg-grid-3 > *.grid-cell:nth-of-type(3n) {
        grid-column: 3;
        -ms-grid-column: 3;
    }

    .lg-grid-4 {
        grid-template-columns: repeat(4, 1fr);
        -ms-grid-columns: (1fr)[4];
    }
    .lg-grid-4 > *.grid-cell:nth-of-type(4n + 1) {
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .lg-grid-4 > *.grid-cell:nth-of-type(4n + 2) {
        grid-column: 2;
        -ms-grid-column: 2;
    }
    .lg-grid-4 > *.grid-cell:nth-of-type(4n + 3) {
        grid-column: 3;
        -ms-grid-column: 3;
    }
    .lg-grid-4 > *.grid-cell:nth-of-type(4n) {
        grid-column: 4;
        -ms-grid-column: 4;
    }

}
