/*
 * Spaces
 */

:root {

    --space-1: 1px;
    --space-2: 0.7692307692rem; /* 10px */
    --space-3: 1.1538461538rem; /* 15px */
    --space-4: 1.5384615385rem; /* 20px */
    --space-5: 2.3076923077rem; /* 30px */
    --space-6: 3.0769230769rem; /* 40px */
    --space-7: 3.8461538462rem; /* 50px */
    --space-8: 4.6153846154rem; /* 60px */
    --space-9: 9.2307692308rem; /* 120px */

}

.mx-auto {
    margin: 0 auto;
}

@media (--breakpoint-md) {
    .md-ml9 {
        margin-right: var(--space-9);
    }
    .md-mr9 {
        margin-right: var(--space-9);
    }
    .md-mln9 {
        margin-left: calc(-1 * var(--space-9));
    }
    .md-mrn9 {
        margin-right: calc(-1 * var(--space-9));
    }
}
