/*
 * Headers
 */

 @media (--breakpoint-sm) {
    .header__find-apartment__dates-wrapper,
    .header__find-apartment__datepicker-wrapper {
        width: calc(100% - ((12 / 10) * ((2 / 12) * 100%)));
    }
    .header__find-apartment__button-wrapper {
        width: calc((12 / 10) * ((2 / 12) * 100%));
    }
}
