/*
 * Icons
 */

.icon {
    vertical-align: middle;
}

body .icon {
    height: var(--body-font-size);
}

@media (--breakpoint-sm) {
    body .icon {
        height: var(--sm-body-font-size);
    }
}

h1 .icon, .h1 .icon {
    height: var(--h1);
}
h2 .icon, .h2 .icon {
    height: var(--h2);
}
h3 .icon, .h3 .icon {
    height: var(--h3);
}
h4 .icon, .h4 .icon {
    height: var(--h4);
}

@media (--breakpoint-sm) {
    h1 .icon, .sm-h1 .icon {
        height: var(--sm-h1);
    }
    h2 .icon, .sm-h2 .icon {
        height: var(--sm-h6);
    }
    h3 .icon, .sm-h3 .icon {
        height: var(--sm-h5);
    }
    h4 .icon, .sm-h4 .icon {
        height: var(--sm-h4);
    }
}
@media (--breakpoint-md) {
    .sm-h1 .icon {
        height: var(--md-h1);
    }
    .sm-h2 .icon {
        height: var(--md-h2);
    }
    .sm-h3 .icon {
        height: var(--md-h3);
    }
    .sm-h4 .icon {
        height: var(--md-h4);
    }
}
@media (--breakpoint-md) {
    h1 .icon, .md-h1 .icon {
        height: var(--md-h1);
    }
    h2 .icon, .md-h2 .icon {
        height: var(--md-h2);
    }
    h3 .icon, .md-h3 .icon {
        height: var(--md-h3);
    }
    h4 .icon, .md-h4 .icon {
        height: var(--md-h4);
    }
}
@media (--breakpoint-lg) {
    .sm-h1 .icon {
        height: var(--lg-h1);
    }
    .sm-h2 .icon {
        height: var(--lg-h2);
    }
    .sm-h3 .icon {
        height: var(--lg-h3);
    }
    .sm-h4 .icon {
        height: var(--lg-h4);
    }
}
@media (--breakpoint-lg) {
    .md-h1 .icon {
        height: var(--lg-h1);
    }
    .md-h2 .icon {
        height: var(--lg-h2);
    }
    .md-h3 .icon {
        height: var(--lg-h3);
    }
    .md-h4 .icon {
        height: var(--lg-h4);
    }
}
@media (--breakpoint-lg) {
    h1 .icon, .lg-h1 .icon {
        height: var(--lg-h1);
    }
    h2 .icon, .lg-h2 .icon {
        height: var(--lg-h2);
    }
    h3 .icon, .lg-h3 .icon {
        height: var(--lg-h3);
    }
    h4 .icon, .lg-h4 .icon {
        height: var(--lg-h4);
    }
}

/* Burger */

.icon-burger {
    width: 30px;
    height: 26px;
    position: relative;
}

.icon-burger div {
    position: absolute;
    width: 30px;
    height: 1px;
    transform: rotate(0deg) translate(0px, 0px);
    -webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
}

.icon-burger.animate div {
    animation-fill-mode: forwards;
    animation-duration: .18s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

.icon-burger div:first-child {
    top: 6px;
    transform-origin: left;
}
.icon-burger.animate div:first-child {
    animation-name: burgerTopClose;
}

.icon-burger div:last-child {
    bottom: 8px;
    transform-origin: right;
}
.icon-burger.animate div:last-child {
    animation-name: burgerBottomClose;
}

.icon-burger.animate.active div:first-child {
    animation-name: burgerTopOpen;
}

.icon-burger.animate.active div:last-child {
    animation-name: burgerBottomOpen;
}

@keyframes burgerTopOpen {
    0% {
        transform: rotate(0deg) translate(0px, 0px);
    }
    50% {
        transform: rotate(0deg) translate(0px, 6px);
    }
    100% {
        transform: rotate(45deg) translate(0px, -6px);
    }
}
@keyframes burgerTopClose {
    0% {
        transform: rotate(45deg) translate(0px, -6px);
    }
    50% {
        transform: rotate(0deg) translate(0px, 6px);
    }
    100% {
        transform: rotate(0deg) translate(0px, 0px);
    }
}

@keyframes burgerBottomOpen {
    0% {
        transform: rotate(0deg) translate(0px, 0px);
    }
    50% {
        transform: rotate(0deg) translate(0px, -6px);
    }
    100% {
        transform: rotate(-45deg) translate(8px, -14px);
    }
}
@keyframes burgerBottomClose {
    0% {
        transform: rotate(-45deg) translate(8px, -14px);
    }
    50% {
        transform: rotate(0deg) translate(0px, -6px);
    }
    100% {
        transform: rotate(0deg) translate(0px, 0px);
    }
}

/* Chevrons */

.icon-chevron:before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: '';
    display: inline-block;
    height: 0.65em;
    position: relative;
    vertical-align: top;
    width: 0.65em;
}

.icon-chevron.left,
.icon-chevron.right {
    float: inherit;
}

.icon-chevron:before,
.icon-chevron.up:before {
    transform: rotate(-45deg);
    top: 0.35em;
}

.icon-chevron.right:before {
    transform: rotate(45deg);
    /* top: 0.25em; */
    top: 7px;
    /* left: -0.2em; */
}

.icon-chevron.down:before {
    transform: rotate(135deg);
    top: 0;
}

.icon-chevron.left:before {
    transform: rotate(-135deg);
    /* top: 0.25em; */
    top: 7px;
    /* left: 0.15rem; */
}

/* Marker */

.icon-marker {
    height: 18px;
}

.icon-play {
    width: 0;
    height: 0;
    display: block;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 16px solid white;
}

/* Mail */

.icon-mail, body .icon-mail {
    width: 26px;
    height: 18px;
}
