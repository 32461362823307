/*
 * Layout
 */

@media (--breakpoint-md) {
    .md-soft-show {
        display: block;
    }
}

.left-4 {
    left: calc(4/12 * 100%);
}

@media (--breakpoint-md) {
    .sm-centre {
        text-align: center;
    }
}

.h-centre {
    transform: translateX(-50%);
}

.book-button-wrap {
    position: sticky;
    top: 60px;
    z-index:2;
}
.book-button {
    transform: translateY(-100%);
}

.top-25vh {
    top: 25vh;
}

.align-center {
    top:50%;
    transform: translateY(-50%);
    position: relative;
}
.center-button {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.min-height-65vh {
    min-height: 65vh;
}
.min-height-90vh {
    min-height: 90vh;
}
.min-height-100vh {
    min-height: 100vh;
}
.min-width-100 {
    min-width: 100%;
}

@media (--breakpoint-sm) {

    .sm-fixed {
        position: fixed;
    }
    .sm-sticky {
        position: sticky;
    }
    .sm-top-25vh {
        top: 25vh;
    }
    .sm-align-center {
        top:50%;
        transform: translateY(-50%);
        position: relative;
    }

    .sm-height-100 {
        height:100%;
    }
    .sm-min-height-65vh {
        min-height: 65vh;
    }
    .sm-min-height-90vh {
        min-height: 90vh;
    }
    .sm-min-height-100vh {
        min-height: 100vh;
    }

}

@media (--breakpoint-sm) {
    .md-min-height-220px {
        min-height: 200px;
    }
}
